<script>
import {
  computed,
  inject,
  watch,
  reactive,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import FormInput from '@/components/form/FormInput.vue';
import MedicationPlanFieldset from '@/components/MedicationPlanFieldset.vue';

export default {
  components: {
    FormInput,
    MedicationPlanFieldset,
  },
  props: {
    modelValue: Object,
  },
  emits: {
    'update:modelValue': Object,
  },
  setup(props, context) {
    const EventBus = inject('EventBus');
    const createNewMedicationPlan = () => ({
      id: `temp_${uuidv4()}`,
      medication: null,
      givenByNurse: '',
      dosageType: null,
      daysInterval: 0,
      daysRange: '',
      length: 0,
      specialInstructions: '',
      deleted: false,
      valid: true,
    });

    const model = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        context.emit('update:modelValue', val);
      },
    });

    const internalModel = reactive({
      showErrors: false,
    });

    const deleteCycle = () => {
      model.value.medications.forEach((medication, index) => {
        model.value.medications[index].deleted = true;
      });
      model.value.deleted = true;
    };

    const getIndexOfID = (list, id) => {
      let foundIndex = -1;
      list.forEach((item, index) => {
        if (item.id === id) {
          foundIndex = index;
        }
      });
      return foundIndex;
    };

    const errors = computed(() => {
      const errorMap = {
        fields: {
          name: '',
        },
        valid: true,
      };

      if (model.value.name.length <= 0) {
        errorMap.fields.name = 'This field is required.';
        errorMap.valid = false;
      }
      model.value.medications
        .filter(item => !item.deleted)
        .forEach((med) => {
          if (!med.valid) {
            errorMap.valid = false;
          }
        });

      return errorMap;
    });

    watch(errors, () => {
      model.value.valid = errors.value.valid;
    });

    EventBus.on('show-errors', () => {
      internalModel.showErrors = true;
    });

    return {
      createNewMedicationPlan,
      model,
      deleteCycle,
      getIndexOfID,
      internalModel,
      errors,
    };
  },
};
</script>
<template>
  <div class="treatment-cycle">
    <div class="header">
      <span class="title">CYCLE</span>
      <svg
        @click="deleteCycle"
        class="trash-icon"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 7l-.867 12.142A2 2 0 0116.138
          21H7.862a2 2 0 01-1.995-1.858L5 7m5
          4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1
          1 0 00-1 1v3M4 7h16"
        ></path>
      </svg>
    </div>
    <div class="form">
      <div class="form-input">
        <FormInput
          label="Cycle Numbers"
          v-model="model.name"
          hint="separated by commas. eg. '1-2,4,6-9'"
          :error="internalModel.showErrors && errors.fields.name.length > 0"
          :errorMessage="errors.fields.name"
        />
      </div>
    </div>
    <div>
      <h2>Medications</h2>
      <MedicationPlanFieldset
        v-for="plan in model.medications.filter(item => !item.deleted)"
        :key="plan.id"
        title="MEDICATION"
        v-model="model.medications[getIndexOfID(model.medications, plan.id)]"
      />
      <span
        class="add-option"
        @click="model.medications.push(createNewMedicationPlan())"
      >
        <svg
          class="plus-icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
        Add Medication
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .add-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed $TABLE_BORDER_COLOR;
    color: $textPrimary;
    padding: 1rem;
    cursor: pointer;
    transition: all .25s;

    &:hover {
      border: 2px dashed rgb(0, 117, 252);
      color: rgb(0, 117, 252);
      background: rgba(0, 117, 252, 0.1);
    }

    .plus-icon {
      width: 4rem;
      height: 4rem;
    }
  }

  h2 {
    @include header;
    text-align: left;
    padding: 1rem 0;
    font-size: 1.25rem;
    flex: 1;
    color: $secondaryHeaderText;
  }

  .treatment-cycle {
    padding: 1rem;
    background: rgba(0, 117, 252, 0.05);
    border-left: 5px solid rgba(0, 117, 252, 0.6);
    margin-bottom: 1rem;
    padding-bottom: 1.5rem;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;

    .title {
      flex: 1;
      font-weight: bold;
      text-transform: uppercase;
    }
    .trash-icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      padding: 0.5rem;
      margin: -0.5em;
      transition: all .25s;
      cursor: pointer;

      &:hover {
        background: rgba($ERROR_COLOR, 0.1);
        color: $ERROR_COLOR;
      }
    }
  }
  .error {
    color: $ERROR_COLOR;
  }
</style>
