import { render } from "./MedicationPlanFieldset.vue?vue&type=template&id=046ac5a8&scoped=true"
import script from "./MedicationPlanFieldset.vue?vue&type=script&lang=js"
export * from "./MedicationPlanFieldset.vue?vue&type=script&lang=js"

import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css?vue&type=style&index=0&lang=css"
import "./MedicationPlanFieldset.vue?vue&type=style&index=1&id=046ac5a8&lang=scss"
import "./MedicationPlanFieldset.vue?vue&type=style&index=2&id=046ac5a8&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-046ac5a8"

export default script