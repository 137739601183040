<script>
import {
  computed,
  inject,
  reactive,
  watch,
} from 'vue';
import { useQuery } from '@urql/vue';
import FormInput from '@/components/form/FormInput.vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import GetMedications from '@/queries/GetMedications';

export default {
  components: {
    FormInput,
    Multiselect,
  },
  props: {
    modelValue: Object,
    title: String,
  },
  emits: {
    'update:modelValue': Object,
  },
  setup(props, context) {
    const EventBus = inject('EventBus');
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        context.emit('update:modelValue', val);
      },
    });

    const internalModel = reactive({
      showErrors: false,
    });

    const dosageTypes = [
      {
        id: 'daily',
        label: 'Daily',
      },
      {
        id: 'everyXdays',
        label: 'Every "X" Days',
      },
      {
        id: 'specificDays',
        label: 'Specific Days',
      },
    ];

    const medicationsResult = useQuery({
      query: GetMedications,
    });

    const meds = computed(() => {
      if (medicationsResult.data.value && medicationsResult.data.value.medications) {
        return medicationsResult.data.value.medications;
      }
      return [];
    });

    const errors = computed(() => {
      const errorMap = {
        fields: {
          medication: '',
          givenByNurse: '',
          dosageType: '',
          length: '',
          daysRange: '',
          daysInterval: '',
        },
        valid: true,
      };

      if (!model.value.medication) {
        errorMap.fields.medication = 'You must select a medication';
        errorMap.valid = false;
      }
      if (!model.value.dosageType) {
        errorMap.fields.dosageType = 'You must select a dosage type';
        errorMap.valid = false;
      }
      if (!model.value.givenByNurse) {
        errorMap.fields.givenByNurse = 'You must select yes or no';
        errorMap.valid = false;
      }
      if (model.value.dosageType && model.value.dosageType.id === 'everyXdays' && model.value.daysInterval <= 0) {
        errorMap.fields.daysInterval = 'This field is required and must be > 0';
        errorMap.valid = false;
      }
      if (model.value.dosageType && model.value.dosageType.id === 'specificDays' && model.value.daysRange.length <= 0) {
        errorMap.fields.daysRange = 'This field is required';
        errorMap.valid = false;
      }
      if (model.value.dosageType && model.value.dosageType.id !== 'specificDays' && model.value.length <= 0) {
        errorMap.fields.length = 'This field is required and must be > 0';
        errorMap.valid = false;
      }

      return errorMap;
    });

    watch(errors, () => {
      model.value.valid = errors.value.valid;
    });

    EventBus.on('show-errors', () => {
      internalModel.showErrors = true;
    });

    return {
      model,
      meds,
      dosageTypes,
      internalModel,
      errors,
    };
  },
};
</script>
<template>
  <div class="med-plan">
    <div class="header">
      <span class="title">{{title}}</span>
      <svg
        @click="model.deleted = true"
        class="trash-icon"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2
          2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1
          0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        ></path>
      </svg>
    </div>
    <div class="form">
      <div class="form-input">
        <label>Medication</label>
        <Multiselect
          :options="meds"
          v-model="model.medication"
          :customLabel="(item) => item.name"
          trackBy="id"
        />
        <span
          class="error"
          v-if="internalModel.showErrors && errors.fields.medication.length > 0"
        >
          {{errors.fields.medication}}
        </span>
      </div>
      <div class="form-input">
        <label>Given By Nurse</label>
        <Multiselect
          :options="['Yes','No']"
          v-model="model.givenByNurse"
        />
        <span
          class="error"
          v-if="internalModel.showErrors && errors.fields.givenByNurse.length > 0"
        >
          {{errors.fields.givenByNurse}}
        </span>
      </div>
      <div class="form-input">
        <label>Dosage Type</label>
        <Multiselect
          :options="dosageTypes"
          v-model="model.dosageType"
          :customLabel="(item) => item.label"
        />
        <span
          class="error"
          v-if="internalModel.showErrors && errors.fields.dosageType.length > 0"
        >
          {{errors.fields.dosageType}}
        </span>
      </div>
      <div class="form-input" v-if="model.dosageType && model.dosageType.id !== 'specificDays'">
        <FormInput
          label="Duration (days)"
          type="number"
          v-model="model.length"
          :error="internalModel.showErrors && errors.fields.length.length > 0"
          :errorMessage="errors.fields.length"
        />
      </div>
      <!--<div class="form-input">
        <FormInput
          label="Special Instructions"
          v-model="model.specialInstructions"
        />
      </div>-->
      <div class="form-input" v-if="model.dosageType && model.dosageType.id === 'specificDays'">
        <FormInput
          label="Days"
          hint="specify specific days, ranges or both; separated by commas. eg. '1-2,4,6-9'"
          type="text"
          v-model="model.daysRange"
          :error="internalModel.showErrors && errors.fields.daysRange.length > 0"
          :errorMessage="errors.fields.daysRange"
        />
      </div>
      <div class="form-input" v-if="model.dosageType && model.dosageType.id === 'everyXdays'">
        <FormInput
          label="Interval (X Days)"
          type="number"
          v-model="model.daysInterval"
          :error="internalModel.showErrors && errors.fields.daysInterval.length > 0"
          :errorMessage="errors.fields.daysInterval"
        />
      </div>
    </div>
  </div>
</template>
<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>
<style lang="scss">
  .multiselect__tags {
    height: 42px;
  }
  .multiselect__single {
    margin-top: 2px;
  }
  .multiselect__option--highlight {
    background: $textPrimary !important;
    &:after {
      background: $textPrimary !important;
    }
  }
</style>
<style lang="scss" scoped>
  .med-plan {
    padding: 1rem;
    background: rgba(0,0,0,.05);
    border-left: 5px solid rgba(0,0,0,0.2);
    margin-bottom: 1rem;
    padding-bottom: 1.5rem;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @include tablet {
      grid-template-columns: 1fr;
    }
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;

    .title {
      flex: 1;
      font-weight: bold;
      text-transform: uppercase;
    }
    .trash-icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      padding: 0.5rem;
      margin: -0.5em;
      transition: all .25s;
      cursor: pointer;

      &:hover {
        background: rgba($ERROR_COLOR, 0.1);
        color: $ERROR_COLOR;
      }
    }
  }
  .error {
    color: $ERROR_COLOR;
  }
</style>
