/* eslint-disable import/prefer-default-export */

// Pull in the model from "TreatmentPlanCreate" and convert it to a usable
// graphql input.
export const processNewTreatmentInput = (model) => {
  const output = {
    name: model.name,
    shortName: model.shortName,
    length: parseInt(model.length, 10),
    premedOptions: [],
    antiviralOptions: [],
    bloodClotPreventionOptions: [],
    medicationsForAllCycles: [],
    cycles: [],
  };

  const medPlanArrayNames = [
    'premedOptions',
    'antiviralOptions',
    'bloodClotPreventionOptions',
    'medicationsForAllCycles',
  ];

  medPlanArrayNames.forEach((medPlanArrayName) => {
    model[medPlanArrayName].forEach((medplan) => {
      if (!medplan.deleted) {
        output[medPlanArrayName].push({
          medication: medplan.medication.id,
          givenByNurse: medplan.givenByNurse === 'Yes',
          dosageType: medplan.dosageType.id,
          daysInterval: parseInt(medplan.daysInterval, 10),
          daysRange: medplan.daysRange,
          length: parseInt(medplan.length, 10),
          specialInstructions: medplan.specialInstructions,
        });
      }
    });
  });

  model.cycles.forEach((cycle) => {
    if (!cycle.deleted) {
      const newCycle = {
        name: cycle.name,
        medications: [],
      };

      cycle.medications.forEach((medplan) => {
        if (!medplan.deleted) {
          newCycle.medications.push({
            medication: medplan.medication.id,
            givenByNurse: medplan.givenByNurse === 'Yes',
            dosageType: medplan.dosageType.id,
            daysInterval: parseInt(medplan.daysInterval, 10),
            daysRange: medplan.daysRange,
            length: parseInt(medplan.length, 10),
            specialInstructions: medplan.specialInstructions,
          });
        }
      });
      output.cycles.push(newCycle);
    }
  });

  return output;
};

export const processEditTreatmentInput = (model) => {
  const output = {
    id: model.id,
    name: model.name,
    shortName: model.shortName,
    length: parseInt(model.length, 10),
    premedOptions: [],
    antiviralOptions: [],
    bloodClotPreventionOptions: [],
    medicationsForAllCycles: [],
    cycles: [],
  };

  const medPlanArrayNames = [
    'premedOptions',
    'antiviralOptions',
    'bloodClotPreventionOptions',
    'medicationsForAllCycles',
  ];

  medPlanArrayNames.forEach((medPlanArrayName) => {
    model[medPlanArrayName].forEach((medplan) => {
      let isNew = false;
      if (typeof medplan.id === 'string' && medplan.id.includes('temp_')) {
        isNew = true;
      }
      if (!(isNew && medplan.deleted)) {
        output[medPlanArrayName].push({
          id: isNew ? -1 : medplan.id,
          new: isNew,
          delete: medplan.deleted,
          medication: medplan.medication.id,
          givenByNurse: medplan.givenByNurse === 'Yes',
          dosageType: medplan.dosageType.id,
          daysInterval: parseInt(medplan.daysInterval, 10),
          daysRange: medplan.daysRange,
          length: parseInt(medplan.length, 10),
          specialInstructions: medplan.specialInstructions,
        });
      }
    });
  });

  model.cycles.forEach((cycle) => {
    let isNewCycle = false;
    if (typeof cycle.id === 'string' && cycle.id.includes('temp_')) {
      isNewCycle = true;
    }
    if (!(isNewCycle && cycle.deleted)) {
      const newCycle = {
        id: isNewCycle ? -1 : cycle.id,
        new: isNewCycle,
        delete: cycle.deleted,
        name: cycle.name,
        medications: [],
      };

      cycle.medications.forEach((medplan) => {
        let isNew = false;
        if (typeof medplan.id === 'string' && medplan.id.includes('temp_')) {
          isNew = true;
        }
        if (!(isNew && medplan.deleted)) {
          newCycle.medications.push({
            id: isNew ? -1 : medplan.id,
            new: isNew,
            delete: medplan.deleted,
            medication: medplan.medication.id,
            givenByNurse: medplan.givenByNurse === 'Yes',
            dosageType: medplan.dosageType.id,
            daysInterval: parseInt(medplan.daysInterval, 10),
            daysRange: medplan.daysRange,
            length: parseInt(medplan.length, 10),
            specialInstructions: medplan.specialInstructions,
          });
        }
      });
      output.cycles.push(newCycle);
    }
  });

  return output;
};
